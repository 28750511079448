// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apiUrlPrefix: '',
  azureADB2CConfiguration:{
    clientId:'e988ff8d-7fa3-4721-b693-5f3ebd956acd',
    signUpSignIn:{
        name:'B2C_1A_SIGNIN_GK',
        authority:'https://devlogin.williams.com/18f1a6b6-72d2-4ebb-9d6a-2bf64b11921a/B2C_1A_SIGNIN_GK'
    },
    editProfile:{
        name:'B2C_1A_V2PROFILEEDIT',
        authority:'https://devlogin.williams.com/18f1a6b6-72d2-4ebb-9d6a-2bf64b11921a/B2C_1A_V2PROFILEEDIT'
    },
    authorityDomain:'devlogin.williams.com',
    scopes:{
        openId: 'https://devlogin.williams.com/13ba5553-9a86-4541-b6d8-d83be4ce9796/api/access'
    }
  },
  federationAPI_URL: '/assets/config/prod/module-federation.manifest.json',
  commonConfigURL: '/assets/config/common-config-data.json',
  configURL: '/assets/config/prod/config-data.json',
  getServerTimeURL: '/public-svc/api/public-service/v1/public/date',
  clientErrorURL: '/assets/config/client-error-data.json',
  siteKey:"6Lf2ruQjAAAAANgkBOFoFU8xj2Y-Hf79AEFPPwTg"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
